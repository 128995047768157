.burger{
    width:45px;
    height:30px;
    display: inline-block;
    margin-right: 10px;
    transition:.3s;
    margin-top:-11px;
    background-color:transparent;
    border:0;

    @include on-event{
        outline:0;
    }

    @media(min-width: 992px){
        display: none;
    }

    span{
        display: block;
        width: 25px;
        height: 2px;
        background-color: #179494;
        margin-top: 6px;
        transition:.3s;
        
        &:nth-child(2){
            transition:.1s !important;
        }
    }


    &.active{
        /* margin-top:-20px; */
        margin-bottom: 2rem;

        span:nth-child(1){
            transform:rotate(45deg);
            margin-top: 13px;
        }
        span:nth-child(2){
            opacity:0;
        }
        span:nth-child(3){
            transform: rotate(-45deg);
            margin-top: -10px;
        }
    }
}