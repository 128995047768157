@charset "UTF-8";
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* przykład 
##SCSS 
div {
  @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
}
 ##CSS 
div {
  -webkit-transform: scale3d(2.5, 2, 1.5);
  -moz-transform: scale3d(2.5, 2, 1.5);
  -ms-transform: scale3d(2.5, 2, 1.5);
  -o-transform: scale3d(2.5, 2, 1.5);
  transform: scale3d(2.5, 2, 1.5);
}
*/
/* Vertical Centering SCSS */
/*
## SCSS 
div {
  @include vertical-center();
}
## CSS 
div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
*/
/* Retina-Ready Image SCSS */
/* 
###SCSS
.image {
  background: url("my-image.png") no-repeat;
  @include retina-image("my-image2x.png", 1000px, 500px);
}
###CSS
.image {
  background: url("my-image.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), 
(-o-min-device-pixel-ratio: 2.6 / 2), 
(-webkit-min-device-pixel-ratio: 1.3), 
(min-device-pixel-ratio: 1.3), 
(min-resolution: 1.3dppx) {
  .image {
    background-image: url("my-image2x.png");
    background-size: 1000px 500px;
  }
}
 */
/* Example of use is at the bottom of file */
/* Example of use is at the bottom of file */
/*

  Sample file name:
    • OpenSans-Bold.ttf
    • [prefix][font_weight].ttf

  Mixin args:
    • path with prefix of files
    • font family
    • fonts weights: 
      • name (font_weight in file names)
      • weight
      • style

  Example:
    $Roboto : (
      (
        'name'   : 'Regular',
        'weight' : 400,
        'style'  : 'normal'
      ),
      (
        'name'   : 'Medium',
        'weight' : 500,
        'style'  : 'normal'
      ),
      (
        'name'   : 'Bold',
        'weight' : 700,
        'style'  : 'normal'
      )
    );
    
    @include fontFace('../sources/fonts/Roboto/Roboto-', 'Roboto', $Roboto);

*/
/*
  Example:

    ul {
      margin-bottom: 15px;

      @include rwd(tablet, true) {
        margin-bottom: 30px;
      }
      
      li {
        margin-bottom: 20px;

        @include rwd(tablet) {
          margin-bottom: 10px;
        }
      }
    }

  Result:

    ul {
      margin-bottom: 15px;
    }

    ul li {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      ul {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 768px) {
      ul li {
        margin-bottom: 10px;
      }
    }

*/
body {
  color: #242630;
  font-family: "Butler", sans-serif;
}

.mainContainer {
  max-width: 1600px;
  margin: 0 auto;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.container-fluid {
  width: 100%;
}

@media (min-width: 1025px) {
  .container-fluid {
    padding-right: unset;
    padding-left: unset;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding-right: unset;
    padding-left: unset;
  }
}

a {
  color: #000;
}

a:hover {
  color: #ac3c07;
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/* BACKGROUND */
/* COLORS */
/*  others */
p {
  font-size: 18px;
  font-weight: 200;
}

@media (min-width: 768px) {
  p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
}

.aLeft {
  text-align: left;
}

.aRight {
  text-align: right;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #890b01;
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 480px) {
  .psuedo-background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.novisible {
  display: none !important;
}

.showvisible {
  display: block !important;
  padding: 0 !important;
}

.centerOfScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Yep! */
  width: 48%;
  height: 59%;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_Ultra_Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../fonts/Butler/Butler_Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

.About {
  background-image: url("../images/bg_about.png");
  background-repeat: no-repeat;
  padding-top: 3rem;
}

.About .container {
  margin-bottom: 5rem;
}

.About p {
  color: #242630;
}

.About__Left__Logo {
  text-align: left;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .About__Left__Logo {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .About__Left__Logo img {
    width: 80%;
  }
}

.About__Left__title {
  margin: 5rem 0 2rem;
  opacity: 1;
}

.About__Left__title__Title {
  font-size: 23px;
  font-weight: 800;
  color: #AC3C07;
  text-align: justify;
  line-height: 40px;
}

@media (max-width: 768px) {
  .About__Left__title__Title {
    font-size: 18px;
    text-align: center;
  }
}

.About__Left__title__Text {
  margin: 1rem auto;
  text-align: justify;
}

@media (max-width: 768px) {
  .About__Left__title__Text {
    text-align: center;
  }
}

.About__Right {
  margin-top: 2rem;
}

.About__Right .owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
}

.About__Right .owl-carousel .owl-item img {
  border-radius: 80px;
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin-top: -11px;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #179494;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  /* margin-top:-20px; */
  margin-bottom: 2rem;
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #000000;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #242630 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #000000;
  margin-left: 13px;
  font-size: 13px !important;
  color: #242630 !important;
  margin: 2px;
  display: inline-block;
}

.footer {
  background-color: #F9A61B;
  margin-top: -7px;
}

.footer__Title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #890B01;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

@media (max-width: 768px) {
  .footer__Title {
    font-size: 22px;
    margin-bottom: 1rem;
  }
}

.footer h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #890B01;
  margin-top: 2rem;
}

.footer h3.hours {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .footer h3 {
    font-size: 18px;
  }
}

.footer p, .footer a {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

@media (max-width: 768px) {
  .footer p, .footer a {
    font-size: 16px;
  }
}

.footer a:hover {
  text-decoration: underline;
}

.footer__Socialmedia {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  align-items: baseline;
}

.footer__Socialmedia__txt {
  margin-right: 2rem;
  font-size: 17px;
  color: #890B01;
}

@media (max-width: 768px) {
  .footer__Socialmedia__txt {
    font-size: 14px;
  }
}

.footer__Socialmedia__ico {
  margin-right: 1rem;
}

.AfterFooter {
  background-color: #fff;
}

.AfterFooter__copyBox {
  padding: 10px 0;
  font-size: 14px;
}

@media (max-width: 768px) {
  .AfterFooter__copyBox {
    font-size: 12px;
  }
}

.AfterFooter__copyBox__Model {
  text-align: left;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Model {
    text-align: center;
  }
}

.AfterFooter__copyBox__Anronet {
  text-align: right;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Anronet {
    text-align: center;
  }
}

.Gallery {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .Gallery {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.Gallery .owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 20px;
}

header {
  height: 4rem;
  position: fixed;
  width: 100%;
  top: 2rem;
  z-index: 1;
}

@media (max-width: 768px) {
  header {
    height: 3rem;
    top: 0rem;
    z-index: 2;
    background: #f9a61b;
    padding-top: 1rem;
  }
}

header .SocialMedia {
  position: relative;
  float: right;
  right: 2rem;
}

header .SocialMedia ul {
  display: flex;
  align-items: center;
}

header .SocialMedia ul li {
  margin-right: 10px;
}

header .SocialMedia ul li.SocialMedia__Title {
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
}

header .SocialMedia ul li a img {
  height: 40px;
  width: 40px;
}

@media (max-width: 768px) {
  header .SocialMedia {
    display: none;
  }
}

header .LogoMobile {
  position: relative;
  float: right;
  right: 2rem;
}

@media (min-width: 769px) {
  header .LogoMobile {
    display: none;
  }
}

@media (max-width: 768px) {
  header .LogoMobile {
    right: 1rem;
    margin-top: -.5rem;
  }
}

header .LogoMobile img {
  height: 35px;
  width: auto;
}

.burger-wrapper {
  cursor: pointer;
}

#meny {
  background: #000;
  width: 50rem;
  height: 45vh;
  position: fixed;
  left: 0;
  transition-timing-function: cubic-bezier(10, 2, 3, 1);
  transform: translateX(-50rem);
  top: 0;
  z-index: 1;
  transition: 0.5s;
}

@media (max-width: 768px) {
  #meny {
    height: 100vh;
  }
}

#meny ul.navMenu {
  margin-top: 5rem;
  margin-left: 2rem;
}

#meny ul.navMenu li.item a {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
}

#meny ul.navMenu li.item a:hover {
  color: #666;
}

#meny ul.flag {
  margin-top: 1rem;
  margin-left: 2rem;
  display: flex;
}

#meny ul.flag li {
  margin-right: 10px;
}

#meny ul.flag li a img {
  height: 20px;
  width: 34px;
}

#meny .SocialMediaMobile {
  margin-top: 1rem;
  margin-left: 2rem;
}

#meny .SocialMediaMobile ul {
  display: flex;
  align-items: center;
}

#meny .SocialMediaMobile ul li {
  margin-right: 10px;
}

#meny .SocialMediaMobile ul li.SocialMedia__Title {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 800;
}

#meny .SocialMediaMobile ul li a img {
  height: 40px;
  width: 40px;
}

@media (min-width: 769px) {
  #meny .SocialMediaMobile {
    display: none;
  }
}

#menyAvPaa,
#HomeControl,
#AboutControl,
#ProductsControl,
#MenuRestaurantControl,
#GalleryControl,
#LocationControl,
#ContactControl {
  display: none;
}

#menyAvPaa:checked ~ #meny {
  transform: translateX(0rem);
}

.HideMenu ~ #meny {
  transform: translateX(-50rem);
}

#burger {
  position: absolute;
  cursor: pointer;
  width: 2rem;
  height: 1.3rem;
  left: 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media (max-width: 768px) {
  #burger {
    left: 1rem;
  }
}

#burger > div {
  height: 4px;
  background-color: #000;
  transition: 0.5s;
  z-index: 999;
}

#menyAvPaa:checked ~ #burger > div {
  background-color: #fff;
}

#menyAvPaa:checked ~ #burger > div:nth-child(1) {
  transform: translateY(2px) rotate(45deg);
}

#menyAvPaa:checked ~ #burger > div:nth-child(2) {
  opacity: 0;
}

#menyAvPaa:checked ~ #burger > div:nth-child(3) {
  transform: translateY(-15px) rotate(-45deg);
}

.MainSlider {
  /* height: 100vh; */
  padding-top: 0;
  background: #f9a61b 0 0 no-repeat padding-box;
}

.MainSlider .PictureBg {
  background-image: url("../images/tramezzini-arrotolati.png");
  background-repeat: no-repeat;
  background-position: right;
}

.MainSlider__Left {
  opacity: 1;
  margin-top: 1rem;
}

.MainSlider__Left__Logo {
  text-align: left;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .MainSlider__Left__Logo {
    display: none;
  }
}

.MainSlider__Left__title {
  margin: 3rem 0 2rem;
  opacity: 1;
}

@media (max-width: 768px) {
  .MainSlider__Left__title {
    margin: 6rem 0 2rem;
  }
}

.MainSlider__Left__title__Title {
  font-size: 26px;
  font-weight: 800;
  color: #000;
  text-align: center;
  line-height: 40px;
}

.MainSlider__Left__title__Img {
  margin: 3rem auto 2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .MainSlider__Right__Picture {
    display: none;
  }
}

.MainSlider .scroll {
  position: relative;
  top: -7rem;
  text-align: center;
  width: 100%;
}

.MainSlider .scroll span {
  display: block;
  color: #040505;
  font-size: 12px;
  font-weight: 800;
}

.MainSlider .scroll .buttonScrollDown {
  cursor: pointer;
}

@media (max-width: 768px) {
  .MainSlider .scroll {
    display: none;
  }
}

.map iframe {
  width: 100%;
  height: 800px;
}

@media (max-width: 768px) {
  .map iframe {
    max-height: 60vh;
  }
}

.Menu {
  background-image: url("../images/tlo-powtarzalne.png");
  background-repeat: repeat;
}

.Menu .container {
  margin-bottom: 5rem;
}

.Menu__title {
  margin: 5rem 0 2rem;
  border-bottom: 1px solid #AC3C07;
  opacity: 1;
}

.Menu__title__Title {
  font-size: 50px;
  font-weight: 800;
  color: #890B01;
}

.Menu__dishes__name {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.Menu__prices__row {
  margin-bottom: 1rem;
}

.Menu__prices__Name {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.Menu__prices__Products {
  font-size: 20px;
  font-weight: 300;
  text-transform: lowercase;
}

.Menu__prices__Price {
  font-size: 20px;
  font-weight: 800;
}

.Menu .nav-pills .nav-link.active, .Menu .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #AC3C07;
}

.Menu .nav-pills .nav-link {
  border-radius: 0;
}

.MenuMobile {
  background-image: url("../images/tlo-powtarzalne.png");
  background-repeat: repeat;
}

.MenuMobile .container {
  margin-bottom: 5rem;
}

.MenuMobile__title {
  margin: 5rem 0 2rem;
  border-bottom: 1px solid #AC3C07;
  opacity: 1;
}

.MenuMobile__title__Title {
  font-size: 40px;
  font-weight: 800;
  color: #890B01;
}

.MenuMobile__dishes [aria-expanded="true"] {
  color: #fff !important;
  background-color: #ac3c07;
}

.MenuMobile__dishes__name {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #890B01;
  background-color: none;
  padding: .5rem;
}

.MenuMobile__prices__row {
  margin-bottom: 1rem;
  display: flex;
}

.MenuMobile__prices__row > div {
  padding: 0;
}

.MenuMobile__prices__Name {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.MenuMobile__prices__Products {
  color: #464749;
  font-size: 18px;
  font-weight: 300;
  text-transform: lowercase;
}

.MenuMobile__prices__Price {
  font-size: 18px;
  font-weight: 800;
  text-align: right;
}

.MenuMobile .nav-pills .nav-link.active, .MenuMobile .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #AC3C07;
}

.MenuMobile .nav-pills .nav-link {
  border-radius: 0;
}

/* 
@include rwd(large-phone) {
    #navbarNavDropdown {
        display: block !important;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1rem;
    }    
}
 */
ul#menu-main-manu .bold a {
  font-weight: 700 !important;
}

ul#menu-main-manu li {
  padding: 0 10px;
}

ul#menu-main-manu a {
  color: #339A99;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 991px) {
  ul#menu-main-manu a {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  ul#menu-main-manu a {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  ul#menu-main-manu a:hover {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

.navbar-collapse {
  padding: 10px 0;
}

@media (max-width: 991px) {
  .navbar-collapse ul li {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .navbar-collapse {
    padding: 0;
  }
}

.navbar-collapse .nav-item:nth-child(n+4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(1) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(2) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(3) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(4) {
  margin-right: 10px;
}

.navbar-collapse .nav-item:nth-child(5) {
  margin-right: 20px;
  margin-left: 10px;
}

.navbar-collapse .nav-item.bold a {
  font-weight: 700 !important;
}

.navbar-collapse .nav-item.dropdown > a {
  padding-right: 27px !important;
  background: url("../images/svg/downArrow.svg") no-repeat top 16px right 10px;
  background-size: 12px;
}

.navbar-collapse .nav-item .nav-link {
  margin: 2px 5px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
  padding: 10px 5px 5px 0;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 12px;
    margin: 0 3px;
    padding: 9px 3px 5px 0 !important;
  }
}

@media (min-width: 1100px) {
  .navbar-collapse .nav-item .nav-link {
    padding: 9px 5px 5px 0 !important;
  }
}

@media (min-width: 1139px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 12px;
    margin: 0 3px;
  }
}

@media (min-width: 1410px) {
  .navbar-collapse .nav-item .nav-link {
    margin: 0 5px;
    padding: 9px nn5px 5px 0 !important;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .navbar-collapse .nav-item .nav-link {
    color: #111110;
    margin: 0px 0px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .3px;
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
}

@media (max-width: 991px) {
  .navbar-collapse .nav-item .nav-link {
    display: block;
  }
}

.navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse .nav-item.active > .hvr-border-fade {
  box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0);
}

.navbar-collapse .dropdown:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
  -webkit-animation-duration: unset;
  animation-duration: unset;
  -webkit-animation-timing-function: unset;
  animation-timing-function: unset;
  -webkit-animation-iteration-count: unset;
  animation-iteration-count: unset;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu {
  background-color: transparent;
  border: 0 !important;
  border-radius: 5px;
  min-width: 250px;
}

@media (max-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    padding-top: 0;
    padding-left: 7px;
  }
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    margin-top: -5px;
    display: none;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link {
  transition: .2s;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu .nav-link {
    padding: 11px 7px 9px 7px !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade {
  border: 0 !important;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade:hover {
  border: 0 !important;
  border-bottom: 1px solid #666 !important;
  box-shadow: none !important;
}

.Products {
  background-color: #242630;
}

.Products .container {
  margin-bottom: 5rem;
}

.Products p {
  color: #fff;
}

.Products__title {
  margin: 5rem 0 2rem;
  opacity: 1;
  text-align: center;
}

.Products__title__Title {
  font-size: 40px;
  font-weight: 800;
  color: #fff;
}

@media (max-width: 768px) {
  .Products__title__Title {
    font-size: 30px;
  }
}

.Products__title__Subtitle {
  font-size: 24px;
  font-weight: 400;
  color: #F16829;
}

@media (max-width: 768px) {
  .Products__title__Subtitle {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
}

.Products__title__Text {
  margin: 1rem auto;
}

@media (min-width: 769px) {
  .Products__title__Text {
    width: 60%;
  }
}

.Products__blocks {
  margin: 1rem 0;
  align-items: center;
}

.Products__blocks__Text {
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .Products__blocks__Text {
    max-height: unset;
  }
}

.Products__blocks__Text__Title {
  color: #F16829;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .Products__blocks__Text__Title {
    text-align: center;
    margin: 2rem auto;
  }
}

@media (min-width: 769px) {
  .Products__blocks__Text.aRight {
    border-right: 1px dotted #F16829;
  }
}

@media (max-width: 768px) {
  .Products__blocks__Text.aRight {
    border-top: 1px dotted #F16829;
    margin: 2rem auto;
    display: block;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .Products__blocks__Text.aLeft {
    border-left: 1px dotted #F16829;
  }
}

@media (max-width: 768px) {
  .Products__blocks__Text.aLeft {
    border-top: 1px dotted #F16829;
    margin: 2rem auto;
  }
}

@media (max-width: 768px) {
  .Products__blocks__Text p {
    text-align: center;
  }
}

section {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  margin: 0;
}

@media (max-width: 480px) {
  section {
    padding-top: 0;
    padding-bottom: 0;
  }
}
