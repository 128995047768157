.Gallery{
    margin-top: 3rem;
    margin-bottom: 3rem;
    @include rwd(tablet) {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .owl-theme .owl-dots .owl-dot span{
        width: 20px;
        height: 20px;
    }
}