
header {
    height: 4rem;    
    position: fixed;
    width: 100%;
    top: 2rem;
    z-index: 1;
    @include rwd(tablet) {
        height: 3rem;
        top: 0rem;
        z-index: 2;
        background: #f9a61b;
        padding-top: 1rem;
    }
    .SocialMedia{
        position: relative;
        float: right;
        right: 2rem;
        ul{
            display: flex;
            align-items: center;
            li{
                margin-right: 10px;
                &.SocialMedia__Title{
                    color: #000;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 800;
                }
                a{
                    img{
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
        @include rwd(tablet) {
            display: none;
        }
    }
    .LogoMobile{
        position: relative;
        float: right;
        right: 2rem;
        @include rwd(tablet,true) {
            display: none;
        }
        @include rwd(tablet) {
            right: 1rem;
            margin-top: -.5rem;
        }
        img{
            height: 35px;
            width: auto
        }
    }
}
.burger-wrapper {
  cursor: pointer;
}
#meny {
    background: #000;
    width: 50rem;
    height: 45vh;
    @include rwd(tablet) {
        height: 100vh;
    }
    position: fixed;
    left: 0;
    transition-timing-function: cubic-bezier(10,2,3,1);
    transform: translateX(-50rem);
    top: 0;
    z-index: 1;
    transition: 0.5s;
    ul.navMenu{
        margin-top: 5rem;
        margin-left: 2rem;
        li.item{
            a{
                color: #fff;
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 700;
                line-height: 35px;
                &:hover{
                    color: #666
                }
            }
        }
    }
    ul.flag{
        margin-top: 1rem;
        margin-left: 2rem;
        display: flex;
        li{
            margin-right: 10px;
            a{
                img{
                    height: 20px;
                    width: 34px;
                }
            }
        }
    }
    
    .SocialMediaMobile{
        margin-top: 1rem;
        margin-left: 2rem;
        ul{
            display: flex;
            align-items: center;
            li{
                margin-right: 10px;
                &.SocialMedia__Title{
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 800;
                }
                a{
                    img{
                        height: 40px;
                        width: 40px;
                    }
                }
            }
        }
        @include rwd(tablet,true) {
            display: none;
        }
    }
}
#menyAvPaa,
#HomeControl,
#AboutControl,
#ProductsControl,
#MenuRestaurantControl,
#GalleryControl,
#LocationControl,
#ContactControl {
    display: none;
}

#menyAvPaa:checked ~ #meny{
    transform: translateX(0rem);
}
.HideMenu ~ #meny{
    transform: translateX(-50rem);
}

#burger {
    position: absolute;
    cursor: pointer;
    width: 2rem;
    height: 1.3rem;
    left: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include rwd(tablet) {
        left: 1rem;
    }
}

#burger > div {
    height: 4px;
    background-color: #000;
    transition: 0.5s;
    z-index: 999;
}

#menyAvPaa:checked ~ #burger > div {
  background-color: #fff;
}

#menyAvPaa:checked ~ #burger > div:nth-child(1) {
    transform: translateY(2px) rotate(45deg);
}
#menyAvPaa:checked ~ #burger > div:nth-child(2) {
    opacity: 0;
}
#menyAvPaa:checked ~ #burger > div:nth-child(3) {
    transform: translateY(-15px) rotate(-45deg);
}