.Products{
    background-color: #242630;
    .container{
        margin-bottom: 5rem;
    }
    p{
        color: #fff;
    }
    &__title{
        margin: 5rem 0 2rem;
        opacity: 1; 
        text-align: center;
        &__Title{
            font-size: 40px;
            font-weight: 800;
            color: #fff;
            @include rwd(tablet) {
                font-size: 30px;
            }
        }
        &__Subtitle{
            font-size: 24px;
            font-weight: 400;
            color: #F16829;
            @include rwd(tablet) {
                margin-top: 1.5rem;
                margin-bottom: 2rem;
            }
        }
        &__Text{
            @include rwd(tablet,true) {
                width: 60%;
            }
            margin: 1rem auto;
        }
    }
    &__blocks{
        margin: 1rem 0;
        align-items: center;
        &__Text {
            max-height: 200px;
            @include rwd(tablet) {
                max-height: unset;
            }
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            &__Title{
                color: #F16829;
                font-size: 20px;
                font-weight: 800;
                margin-bottom: 1rem;
                @include rwd(tablet) {
                    text-align: center;
                    margin: 2rem auto;
                }
            }
            &.aRight{
                @include rwd(tablet,true) {
                    border-right: 1px dotted #F16829;
                }
                @include rwd(tablet) {
                    border-top: 1px dotted #F16829;
                    margin: 2rem auto;
                    display: block;
                    text-align: center;
                }
            }
            &.aLeft{
                @include rwd(tablet,true) {
                    border-left: 1px dotted #F16829;
                }
                @include rwd(tablet) {
                    border-top: 1px dotted #F16829;
                    margin: 2rem auto;
                    
                }
            }
            p {                
                @include rwd(tablet) {
                    text-align: center;
                }
            }
        }
    }
}