.MainSlider {
    /* height: 100vh; */
    padding-top: 0;
    background: #f9a61b 0 0 no-repeat padding-box;
    .PictureBg{        
        background-image: url('../images/tramezzini-arrotolati.png');
        background-repeat: no-repeat;
        background-position: right;
    }
    &__Left{
        opacity: 1;
        margin-top: 1rem;
        &__Logo{
            text-align: left;
            margin-top: 2rem;
            @include rwd(tablet) {
                display: none;
            }
        }
        &__title{
            margin: 3rem 0 2rem;
            @include rwd(tablet) {
                margin: 6rem 0 2rem;
            }
            opacity: 1; 
            &__Title{
                font-size: 26px;
                font-weight: 800;
                color: #000;
                text-align: center;
                line-height: 40px;;
            }
            &__Img{
                margin: 3rem auto 2rem;
                text-align: center;
            }
        }
    }
    &__Right{
        &__Picture{
            @include rwd(tablet) {
                display: none;
            }
        }
    }
    .scroll{
        position: relative;
        top: -7rem;
        text-align: center;
        width: 100%;
        span{
            display: block;
            color: #040505;
            font-size: 12px;
            font-weight: 800;
        }
        .buttonScrollDown{
            cursor: pointer;
        }
        @include rwd(tablet) {
            display: none;
        }
    }
}