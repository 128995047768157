.footer{
    background-color: #F9A61B;
    margin-top: -7px;
    &__Title{
        text-align: center;
        font-size: 30px;
        font-weight: 800;
        color: #890B01;
        margin-top: 3rem;
        margin-bottom: 2rem;
        @include rwd(tablet) {
            font-size: 22px;
            margin-bottom: 1rem;
        }
    }
    h3{
        text-align: center;
        font-size: 20px;
        font-weight: 800;
        color: #890B01;
        margin-top: 2rem;
        &.hours{
            margin-bottom: 1rem;
        }
        @include rwd(tablet) {
            font-size: 18px;
        }
    }
    p,a{
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        @include rwd(tablet) {
            font-size: 16px;
        }
    }
    a:hover{
        text-decoration: underline;
    }
    &__Socialmedia{
        display: flex;
        justify-content: center;
        margin: 2rem auto;
        align-items: baseline;
        &__txt{
            margin-right: 2rem;
            font-size: 17px;
            color: #890B01;
            @include rwd(tablet) {
                font-size: 14px;
            }
        }
        &__ico{
            margin-right: 1rem;
        }
    }
}

.AfterFooter{
    background-color: #fff;
    &__copyBox {
        padding: 10px 0;
        font-size: 14px;
        @include rwd(tablet) {
            font-size: 12px;
        }
        &__Model{
            text-align: left;
            @include rwd(small-tablet) {
                text-align: center;
            }
        }
        &__Anronet{
            text-align: right;
            @include rwd(small-tablet) {
                text-align: center;
            }
        }
    }
}