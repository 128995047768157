.About{
    background-image: url('../images/bg_about.png');
    background-repeat: no-repeat;
    padding-top: 3rem;
    .container{
        margin-bottom: 5rem;
    }
    p{
        color: #242630;
    }
    &__Left{
        &__Logo{
            text-align: left;
            @include rwd(tablet) {
                text-align: center;
            }
            margin-top: 2rem;
            img{                
                @include rwd(tablet) {
                    width: 80%;
                }
            }
        }
        &__title{
            margin: 5rem 0 2rem;
            opacity: 1; 
            &__Title{
                font-size: 23px;
                font-weight: 800;
                color: #AC3C07;
                text-align: justify;
                line-height: 40px;;
                @include rwd(tablet) {
                    font-size: 18px;
                    text-align: center;
                }
            }
            &__Text{
                margin: 1rem auto;
                text-align: justify;
                @include rwd(tablet) {
                    text-align: center;
                }
            }
        }
    }
    &__Right{
        margin-top: 2rem;
        .owl-theme .owl-dots .owl-dot span{
            width: 20px;
            height: 20px;
        }
        .owl-carousel .owl-item img {
            border-radius: 80px;
        }
    }
}