body{
    color: $cBody;
    font-family: $fFirst;
}
.mainContainer{
    max-width: 1600px;
    margin:0 auto;

    @media (min-width:992px) and (max-width:1199px){
        padding-left:25px;
        padding-right:25px;
    }

    @media (min-width:1200px) and (max-width:1500px){
        padding-left:50px;
        padding-right:50px;
    }
}
.container-fluid {
    width: 100%;
    @include rwd(large-tablet,true) {
        padding-right: unset;
        padding-left: unset;
    }
    @include rwd(tablet) {
        padding-right: unset;
        padding-left: unset;
    }
}

a{
    color: #000;
    &:hover {
        color: #ac3c07;
    }
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
/* BACKGROUND */



/* COLORS */


/*  others */


p{
    font-size:18px;
    font-weight: 200;
    @media(min-width:768px){
        font-size:15px;
    }
    @media(min-width:1200px){
        font-size:16px;
    }
}
.aLeft{
    text-align: left;
}
.aRight{
    text-align: right;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #890b01;
}
.slide{
    width:100%;
    height:100%;
    position:relative;
}

.psuedo-background-img{
    @include rwd(large-phone) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.novisible{
    display: none!important;
}
.showvisible{
    display: block!important;
    padding: 0 !important;
}

.centerOfScreen{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Yep! */
        width: 48%;
        height: 59%;
}