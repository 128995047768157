
    $Butler : (
        (
          'name'   : 'Ultra_Light',
          'weight' : 200,
          'style'  : 'normal'
        ),
        (
          'name'   : 'Light',
          'weight' : 300,
          'style'  : 'normal'
        ),
        (
          'name'   : 'Regular',
          'weight' : 400,
          'style'  : 'normal'
        ),
        (
          'name'   : 'Medium',
          'weight' : 500,
          'style'  : 'normal'
        ),
        (
          'name'   : 'Bold',
          'weight' : 700,
          'style'  : 'normal'
        ),
        (
          'name'   : 'ExtraBold',
          'weight' : 800,
          'style'  : 'normal'
        ),
        (
          'name'   : 'Black',
          'weight' : 900,
          'style'  : 'normal'
        )
      );
      
      @include fontFace('../fonts/Butler/Butler_', 'Butler', $Butler);


