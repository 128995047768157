.Menu{
    background-image: url('../images/tlo-powtarzalne.png');
    background-repeat: repeat;
    .container{
        margin-bottom: 5rem;
    }
    &__title{
        margin: 5rem 0 2rem;
        border-bottom: 1px solid #AC3C07;
        opacity: 1; 
        &__Title{
            font-size: 50px;
            font-weight: 800;
            color: #890B01;
        }
    }
    &__dishes{
        &__name {
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    &__prices{
        &__row{
            margin-bottom: 1rem;
        }
        &__Name{
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
        }
        &__Products{
            font-size: 20px;
            font-weight: 300;
            text-transform: lowercase;
        }
        &__Price{
            font-size: 20px;
            font-weight: 800;
        }
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #AC3C07;
    }
    .nav-pills .nav-link {
        border-radius: 0;
    }
}
.MenuMobile{
    background-image: url('../images/tlo-powtarzalne.png');
    background-repeat: repeat;
    .container{
        margin-bottom: 5rem;
    }
    &__title{
        margin: 5rem 0 2rem;
        border-bottom: 1px solid #AC3C07;
        opacity: 1; 
        &__Title{
            font-size: 40px;
            font-weight: 800;
            color: #890B01;
        }
    }
    &__dishes{
        .heading:focus{
        }
        [aria-expanded="true"]{      
            color: #fff!important;
            background-color: #ac3c07;
        }
        &__name {
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;          
            color: #890B01;
            background-color: none;
            padding: .5rem;
            &.collapse{
            }
        }
    }
    &__prices{
        &__row{
            margin-bottom: 1rem;
            display: flex;
            & > div{
                padding: 0;
            }
        }
        &__Name{
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }
        &__Products{
            color: #464749;
            font-size: 18px;
            font-weight: 300;
            text-transform: lowercase;
        }
        &__Price{
            font-size: 18px;
            font-weight: 800;
            text-align: right;
        }
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #AC3C07;
    }
    .nav-pills .nav-link {
        border-radius: 0;
    }
}