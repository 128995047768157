
/* 
@include rwd(large-phone) {
    #navbarNavDropdown {
        display: block !important;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1rem;
    }    
}
 */
ul#menu-main-manu{
    .bold a{
        font-weight: 700!important;
    }
    li{
            padding: 0 10px;
    }
    a{
        color: #339A99;
        text-transform: uppercase;
        font-size: 18px;
        @include rwd(medium-tablet) {
            font-size: 14px;
        }
        @include rwd(large-tablet) {
            font-size: 14px;
        }
        font-weight: 500;
        &:hover{
            @include rwd(medium-tablet,true) {
                -webkit-animation-name: hvr-pulse;
                animation-name: hvr-pulse;
                -webkit-animation-duration: 1s;
                animation-duration: 1s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
            }
        }
    }
}


.navbar-collapse{
    padding:10px 0;
    @include rwd(medium-tablet) {
        ul li{
            text-align: center;
        }
    }

    @include media(large){
        padding:0;
    }
    .nav-item:nth-child(n+4) {
        margin-right: 10px;
    }

    .nav-item{
        &:nth-child(1) {
            margin-right: 10px;
        }
        &:nth-child(2) {
            margin-right: 10px;
        }
        &:nth-child(3) {
            margin-right: 10px;
        }
        &:nth-child(4) {
            margin-right: 10px;
        }
        &:nth-child(5) {
            margin-right: 20px;
            margin-left: 10px;
        }
        &.bold a{
            font-weight: 700!important;
        }
        &.dropdown{   
                > a{
                    padding-right: 27px !important;
                    background:url('../images/svg/downArrow.svg') no-repeat top 16px right 10px;
                    background-size: 12px;
                    @include rwd(medium-tablet) {
                        
                    }
                }     
        }
            
   

        .nav-link{
            margin:2px 5px;
            font-size:16px;
            font-weight: 500;
            letter-spacing: .3px;
            display:flex;
            align-items: center;
            padding: 10px 5px 5px 0;

            @include media(large){
                font-size:12px;
                margin:0 3px;
                padding: 9px 3px 5px 0 !important;
            }

            @media (min-width:1100px){
                padding: 9px 5px 5px 0 !important;
            }

            @include media(xlarge){
                font-size:12px;
                margin:0 3px;
            }

            @include media(xxlarge){
                margin:0 5px;
                padding:9px nn5px 5px 0 !important;
                font-size:12px;
            }
            @include rwd(tablet) {
                color: #111110;
                margin: 0px 0px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: .3px;
                display: flex;
                align-items: center;
                padding: 5px 0;
            }
            @include rwd(medium-tablet) {
                display: block;
            }
                
                &:hover{
                    text-decoration: underline;
                }
            

        }

    

        &.active{
        & > .hvr-border-fade{
                box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0);
            }
        }

    }

    .dropdown{
        

        &:hover{
            -webkit-animation-name: unset;
            animation-name: unset;
            -webkit-animation-duration: unset;
            animation-duration: unset;
            -webkit-animation-timing-function: unset;
            animation-timing-function: unset;
            -webkit-animation-iteration-count: unset;
            animation-iteration-count: unset;
            
            @media(min-width:992px){
                .dropdown-menu{
                    display: block !important;   
                }
            }
        }

        .dropdown-menu{
            background-color:transparent;  
            border:0 !important; 
            border-radius: 5px;
            min-width: 250px;
            
            //max
            @media(max-width:992px){
                padding-top: 0;
                padding-left: 7px;
            }

            @include media(large){
                margin-top:-5px; 
                display:none;  
                background-color: rgba(255, 255, 255, 0.8); 
            }



            .nav-link{             
                transition:.2s;

                @include media(large){
                    padding:11px 7px 9px 7px !important;   
                }

                &.hvr-border-fade{
                    border: 0 !important;

                    &:hover{
                        border: 0 !important;
                        border-bottom:1px solid #666 !important;   
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
}